


















import { Component, Vue, Prop } from "vue-property-decorator";
import MainHeader from "../../../main/components/main-header/main-header.vue";
import NavLeft from "../../../main/components/nav-left/nav-left.vue";
@Component({
  components: {
    MainHeader,
    NavLeft,
  },
})
export default class Name extends Vue {
  private goRoute(path: any) {
    this.$store.commit("updatePatient", null);
    this.$router.push(path);
  }
}
